import React, { useEffect, useState, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import "./App.css";
import axios from "axios";
import Swal from "sweetalert2";
const App = () => {
  const location = useLocation();
  const { path, state } = location;
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null); // To track the menu element

  // Function to toggle the menu open/close state
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // // Function to close the menu when clicking outside of it
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false); // Close the menu
    }
  };

  useEffect(() => {
    // Add event listener for clicks on the entire document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isSticky, setIsSticky] = useState(false);
  const [isActive, setIsActive] = useState(false);

  // Toggle function to handle click events

  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    phone: "",
    skype: "",
    subject: "",
    websiteName: "",
    message: "",
    agreeToTerms: true
  });

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   setContactForm({
  //     ...contactForm,
  //     [name]: type === 'checkbox' ? checked : value,
  //   });
  // };

  const [errors, setErrors] = useState({}); // Define the errors state

  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setContactForm((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    debugger;
    e.preventDefault();

    // Simple form validation
    if (!validateForm()) {
      // Swal.fire({
      //   icon: "error",
      //   title: "Warning...",
      //   text: "Please fill out all required fields before submitting.",
      // });
      return;
    }

    // Proceed with form submission if validation is successful
    const contactFormData = new FormData();
    contactFormData.append("Name", contactForm.name);
    contactFormData.append("Email", contactForm.email);
    contactFormData.append("Phone", contactForm.phone);
    contactFormData.append("Skype", contactForm.skype);
    contactFormData.append("Subject", contactForm.subject);
    contactFormData.append("WebsiteName", contactForm.websiteName);
    contactFormData.append("Message", contactForm.message);

    axios
      .post(
        "https://ecoapi.softapproachit.com/api/Article/InsertSoftApproachContact",
        contactFormData
      )
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Successful",
            text: "Thank you! We will contact you within 24 hours."
          });

          setContactForm({
            name: "",
            email: "",
            phone: "",
            skype: "",
            subject: "",
            websiteName: "",
            message: "",
            agreeToTerms: true
          });

          setValidated(false);
        } else {
          Swal.fire({
            icon: "success",
            title: "Successful",
            text: "Thank you! We will contact you within 24 hours."
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "success",
          title: "Successful",
          text: "Thank you! We will contact you within 24 hours."
        });
      });
    setContactForm({
      name: "",
      email: "",
      phone: "",
      skype: "",
      subject: "",
      websiteName: "",
      message: "",
      agreeToTerms: true
    });
  };

  const validateForm = () => {
    const form = document.querySelector("form");
    if (form.checkValidity() === false) {
      setValidated(true); // Form has errors
      return false;
    }
    setValidated(false);
    return true;
  };

  const handleClick = () => {
    setIsSticky(!isSticky); // Toggle the sticky state
  };

  const [activeIndex, setActiveIndex] = useState(1);
  const carouselRef = useRef(null); // Reference to OwlCarousel

  const options = {
    loop: true,
    margin: 30,
    nav: false,
    dots: true, // Disable default dots, we'll use custom ones
    items: 1,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Set duration for autoplay
    autoplayHoverPause: true, // Pause on hover
    onTranslated: (e) => {
      setActiveIndex(e.item.index + 1); // Update active index when slide changes
    }
  };

  const optionClients = {
    loop: true,
    nav: false,
    dots: true, // Disable default dots, we'll use custom ones
    items: 1,
    slideBy: 1,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Set duration for autoplay
    autoplayHoverPause: true // Pause on hover
    // onTranslated: (e) => {
    //   setActiveIndex(e.item.index + 1); // Update active index when slide changes
    // },
  };

  const handleDotClick = (index) => {
    setActiveIndex(index);
    carouselRef.current.to(index - 1); // Go to the selected slide, index starts from 0 in `to()`
  };

  const optionsTest = {
    loop: true,
    margin: 25,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    center: true, // Enable center mode
    responsive: {
      0: {
        items: 1, // Show 1 item at a time on very small screens (mobile)
        slideBy: 1
      },
      600: {
        items: 2, // Show 2 items at a time on medium screens (tablet)
        slideBy: 1
      },
      1000: {
        items: 4, // Show 4 items at a time on larger screens (desktop)
        slideBy: 1
      }
    }
  };

  return (
    <>
      {/* <nav
        id="hc-nav-1"
        className="hc-offcanvas-nav hc-nav-1 nav-levels-overlap nav-position-left disable-body nav-close-button-empty nav-open"
        aria-hidden="false"
        aria-labelledby="hc-nav-1-nav-title"
        style={
          isOpen
            ? { visibility: "visible", display: "block" }
            : { visibility: "visible", display: "none" }
        }
      > */}
      <div
        className={`overlay ${isOpen ? "open" : ""}`}
        style={{ zIndex: "9999" }}
      ></div>
      <nav
        id="hc-nav-1"
        className={`hc-offcanvas-nav hc-nav-1 nav-levels-overlap nav-position-left disable-body nav-close-button-empty ${
          isOpen ? "open" : ""
        }`}
        style={{ zIndex: "99999" }}
        aria-hidden={!isOpen}
        aria-labelledby="hc-nav-1-nav-title"
      >
        <div className="nav-container" style={{}} ref={menuRef}>
          <div
            className="nav-wrapper nav-wrapper-0"
            data-level={0}
            data-index={0}
          >
            <div className="nav-content">
              <h2 id="hc-nav-1-nav-title" className="nav-title">
                Mount Saffron
              </h2>
              <div className="nav-close">
                <a
                  href="#"
                  className="nav-close-button"
                  role="menuitem"
                  tabIndex={0}
                  onClick={handleToggle}
                  aria-label="Close Menu"
                >
                  <span />
                </a>
              </div>
              <ul role="menu" aria-level={1}>
                <li className="nav-item">
                  <div className="nav-item-wrapper">
                    <a
                      href="#home"
                      className="menu-links nav-item-link"
                      tabIndex={0}
                      onClick={handleToggle}
                      role="menuitem"
                    >
                      Home
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-item-wrapper">
                    <a
                      href="#about"
                      className="menu-links nav-item-link"
                      tabIndex={0}
                      onClick={handleToggle}
                      role="menuitem"
                    >
                      About Us
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-item-wrapper">
                    <a
                      href="#service"
                      className="menu-links nav-item-link"
                      tabIndex={0}
                      onClick={handleToggle}
                      role="menuitem"
                    >
                      Services
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-item-wrapper">
                    <a
                      href="#work"
                      className="menu-links nav-item-link"
                      tabIndex={0}
                      onClick={handleToggle}
                      role="menuitem"
                    >
                      Our Work
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-item-wrapper">
                    <a
                      href="#review"
                      className="menu-links nav-item-link"
                      tabIndex={0}
                      onClick={handleToggle}
                      role="menuitem"
                    >
                      Clients Review
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-item-wrapper">
                    <a
                      href="#blog"
                      className="menu-links nav-item-link"
                      tabIndex={0}
                      onClick={handleToggle}
                      role="menuitem"
                    >
                      Blogs
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="nav-item-wrapper">
                    <a
                      href="#contact"
                      className="menu-links nav-item-link"
                      tabIndex={0}
                      onClick={handleToggle}
                      role="menuitem"
                    >
                      Contact Us
                    </a>
                  </div>
                </li>
              </ul>
              <ul role="menu" aria-level={1} className="bottom-nav">
                <li className="nav-item ree-hc">
                  <div className="nav-item-wrapper">
                    <a
                      href="tel:123567890"
                      rel="noreferrer"
                      target="_blank"
                      className="nav-item-link"
                      tabIndex={0}
                      onClick={handleToggle}
                      role="menuitem"
                    >
                      {" "}
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 25.625 25.625"
                        style={{ enableBackground: "new 0 0 25.625 25.625" }}
                        xmlSpace="preserve"
                      >
                        <g>
                          {" "}
                          <path
                            d="M22.079,17.835c-1.548-1.324-3.119-2.126-4.648-0.804l-0.913,0.799 c-0.668,0.58-1.91,3.29-6.712-2.234C5.005,10.079,7.862,9.22,8.531,8.645l0.918-0.8c1.521-1.325,0.947-2.993-0.15-4.71l-0.662-1.04  C7.535,0.382,6.335-0.743,4.81,0.58L3.986,1.3C3.312,1.791,1.428,3.387,0.971,6.419c-0.55,3.638,1.185,7.804,5.16,12.375
					   c3.97,4.573,7.857,6.87,11.539,6.83c3.06-0.033,4.908-1.675,5.486-2.272l0.827-0.721c1.521-1.322,0.576-2.668-0.973-3.995 L22.079,17.835z"
                          />
                        </g>
                      </svg>
                    </a>
                  </div>
                </li>
                <li className="nav-item ree-hc">
                  <div className="nav-item-wrapper">
                    <a
                      href="mailto:abc@example.com"
                      target="_blank"
                      className="nav-item-link"
                      tabIndex={0}
                      role="menuitem"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                    </a>
                  </div>
                </li>
                <li className="nav-item ree-hc">
                  <div className="nav-item-wrapper">
                    <a
                      href="skype:MountSaffron.company"
                      rel="noreferrer"
                      target="_blank"
                      className="nav-item-link"
                      tabIndex={0}
                      role="menuitem"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M424.7 299.8c2.9-14 4.7-28.9 4.7-43.8 0-113.5-91.9-205.3-205.3-205.3-14.9 0-29.7 1.7-43.8 4.7C161.3 40.7 137.7 32 112 32 50.2 32 0 82.2 0 144c0 25.7 8.7 49.3 23.3 68.2-2.9 14-4.7 28.9-4.7 43.8 0 113.5 91.9 205.3 205.3 205.3 14.9 0 29.7-1.7 43.8-4.7 19 14.6 42.6 23.3 68.2 23.3 61.8 0 112-50.2 112-112 .1-25.6-8.6-49.2-23.2-68.1zm-194.6 91.5c-65.6 0-120.5-29.2-120.5-65 0-16 9-30.6 29.5-30.6 31.2 0 34.1 44.9 88.1 44.9 25.7 0 42.3-11.4 42.3-26.3 0-18.7-16-21.6-42-28-62.5-15.4-117.8-22-117.8-87.2 0-59.2 58.6-81.1 109.1-81.1 55.1 0 110.8 21.9 110.8 55.4 0 16.9-11.4 31.8-30.3 31.8-28.3 0-29.2-33.5-75-33.5-25.7 0-42 7-42 22.5 0 19.8 20.8 21.8 69.1 33 41.4 9.3 90.7 26.8 90.7 77.6 0 59.1-57.1 86.5-112 86.5z" />
                      </svg>
                    </a>
                  </div>
                </li>
                <li className="nav-item ree-hc">
                  <div className="nav-item-wrapper">
                    <a
                      href="wa.me/91123456789"
                      rel="noreferrer"
                      target="_blank"
                      className="nav-item-link"
                      tabIndex={0}
                      role="menuitem"
                    >
                      {" "}
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                        style={{ enableBackground: "new 0 0 512 512" }}
                        xmlSpace="preserve"
                      >
                        <g>
                          <path
                            d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456 C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504 c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
						   c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184 c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392 c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352 c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024 c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568 c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"
                          />{" "}
                        </g>
                      </svg>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* <!--Start Header--> */}
      {/* <header className="ree-header fixed-top"> */}
      <header className={`ree-header fixed-top ${isSticky ? "sticky" : ""}`}>
        <div className="container-fluid m-p-l-r-0">
          <div className="menu-header">
            <div className="menu-logo">
              <div className="dskt-logo">
                <a className="nav-brand" href="./">
                  {" "}
                  <img
                    src="images/logom.png"
                    alt="Logo"
                    width={240}
                    className="ree-logo"
                  />{" "}
                </a>{" "}
              </div>
            </div>
            <div className="ree-nav" role="navigation">
              <ul className="nav-list">
                <li>
                  <a href="#home" className="menu-links" onClick={handleClick}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="#about" className="menu-links" onClick={handleClick}>
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="#service"
                    className="menu-links"
                    onClick={handleClick}
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a href="#work" className="menu-links" onClick={handleClick}>
                    Our Work
                  </a>
                </li>
                <li>
                  <a
                    href="#review"
                    className="menu-links"
                    onClick={handleClick}
                  >
                    Clients Review
                  </a>
                </li>
                <li>
                  <a href="#blog" className="menu-links" onClick={handleClick}>
                    Blogs
                  </a>
                </li>
                <li>
                  <a
                    href="#contact"
                    className="menu-links"
                    onClick={handleClick}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="ree-nav-cta">
              <ul>
                <li>
                  <a
                    href="#contact"
                    className="ree-btn ree-btn0 ree-btn-grdt2"
                    onClick={handleClick}
                  >
                    {" "}
                    Request Quote
                  </a>{" "}
                </li>
              </ul>
            </div>
            <div className="mobile-menu2">
              <ul className="mob-nav2">
                <li>
                  <a
                    href="#contact"
                    onClick={handleClick}
                    className="ree-btn2 ree-btn-grdt1"
                  >
                    <i className="fas fa-envelope-open-text" />
                  </a>
                </li>
                <li className="navm-">
                  {/* <a className="toggle" href="#">
                    {" "}
                    <span />{" "}
                  </a>{" "} */}
                  <a
                    className={`toggle hc-nav-trigger hc-nav-1 ${
                      isOpen ? "toggle-open" : ""
                    }`}
                    href="#"
                    role="button"
                    aria-label={isOpen ? "Close Menu" : "Open Menu"}
                    aria-controls="hc-nav-1"
                    aria-expanded={isOpen ? "true" : "false"}
                    onClick={handleToggle}
                  >
                    <span></span>
                  </a>
                </li>
              </ul>
            </div>
            <nav id="main-nav">
              <ul>
                <li>
                  <a href="#home" className="menu-links">
                    Home
                  </a>
                </li>
                <li>
                  <a href="#about" className="menu-links">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#service" className="menu-links">
                    Services
                  </a>
                </li>
                <li>
                  <a href="#work" className="menu-links">
                    Our Work
                  </a>
                </li>
                <li>
                  <a href="#review" className="menu-links">
                    Clients Review
                  </a>
                </li>
                <li>
                  <a href="#blog" className="menu-links">
                    Blogs
                  </a>
                </li>
                <li>
                  <a href="#contact" className="menu-links">
                    Contact Us
                  </a>
                </li>
              </ul>
              <ul className="bottom-nav">
                <li className="ree-hc">
                  <a href="tel:123567890" rel="noreferrer" target="_blank">
                    {" "}
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 25.625 25.625"
                      style={{ enableBackground: "new 0 0 25.625 25.625" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        {" "}
                        <path
                          d="M22.079,17.835c-1.548-1.324-3.119-2.126-4.648-0.804l-0.913,0.799 c-0.668,0.58-1.91,3.29-6.712-2.234C5.005,10.079,7.862,9.22,8.531,8.645l0.918-0.8c1.521-1.325,0.947-2.993-0.15-4.71l-0.662-1.04  C7.535,0.382,6.335-0.743,4.81,0.58L3.986,1.3C3.312,1.791,1.428,3.387,0.971,6.419c-0.55,3.638,1.185,7.804,5.16,12.375
					   c3.97,4.573,7.857,6.87,11.539,6.83c3.06-0.033,4.908-1.675,5.486-2.272l0.827-0.721c1.521-1.322,0.576-2.668-0.973-3.995 L22.079,17.835z"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                <li className="ree-hc">
                  <a href="mailto:abc@example.com" target="_blank">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                      <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                  </a>
                </li>
                <li className="ree-hc">
                  <a
                    href="skype:mount saffron.company"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M424.7 299.8c2.9-14 4.7-28.9 4.7-43.8 0-113.5-91.9-205.3-205.3-205.3-14.9 0-29.7 1.7-43.8 4.7C161.3 40.7 137.7 32 112 32 50.2 32 0 82.2 0 144c0 25.7 8.7 49.3 23.3 68.2-2.9 14-4.7 28.9-4.7 43.8 0 113.5 91.9 205.3 205.3 205.3 14.9 0 29.7-1.7 43.8-4.7 19 14.6 42.6 23.3 68.2 23.3 61.8 0 112-50.2 112-112 .1-25.6-8.6-49.2-23.2-68.1zm-194.6 91.5c-65.6 0-120.5-29.2-120.5-65 0-16 9-30.6 29.5-30.6 31.2 0 34.1 44.9 88.1 44.9 25.7 0 42.3-11.4 42.3-26.3 0-18.7-16-21.6-42-28-62.5-15.4-117.8-22-117.8-87.2 0-59.2 58.6-81.1 109.1-81.1 55.1 0 110.8 21.9 110.8 55.4 0 16.9-11.4 31.8-30.3 31.8-28.3 0-29.2-33.5-75-33.5-25.7 0-42 7-42 22.5 0 19.8 20.8 21.8 69.1 33 41.4 9.3 90.7 26.8 90.7 77.6 0 59.1-57.1 86.5-112 86.5z" />
                    </svg>
                  </a>
                </li>
                <li className="ree-hc">
                  <a href="wa.me/91123456789" rel="noreferrer" target="_blank">
                    {" "}
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style={{ enableBackground: "new 0 0 512 512" }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <path
                          d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456 C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504 c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
						   c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184 c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392 c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352 c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024 c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568 c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"
                        />{" "}
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <>
        <br />
        <br />
        {/*start Hero Section  */}
        <section className="home-hero-a" id="home">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 vcenter">
                <div className="hero-content-a">
                  <h1
                    className="h1 mb30"
                    data-aos="fade-in"
                    data-aos-delay={200}
                  >
                    <span className="ree-text rt60">Top Web </span>
                    and Mobile App Development Company in India
                  </h1>
                  <p data-aos="fade-in" data-aos-delay={500}>
                    At Mount Saffron IT, we tackle the world's most significant
                    challenges with our expertise. We partner with global brands
                    to create innovative digital products across web, mobile,
                    and connected platforms, driving impactful solutions.
                  </p>
                  <a
                    href="#contact"
                    className="ree-btn  ree-btn-grdt1 mt40 mw-80"
                    data-aos="fade-in"
                    data-aos-delay={800}
                  >
                    Let's Talk <i className="fas fa-arrow-right fa-btn" />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 vcenter">
                <div className="sol-image m-mt30">
                  <img
                    src="images/sliders/girl-bro.png"
                    alt="web development"
                    className="img-fluid"
                    data-aos="fade-in"
                    data-aos-delay={400}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*hero Section End*/}
        {/*start home-about  */}
        <section className="home-about sec-pad r-bg-a" id="about">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="about-content-home m-mb60 text-center">
                  <span className="sub-heading mb15">
                    We Are Mount Saffron IT
                  </span>
                  <h2 className="mb15">
                    <span className="ree-text rt40">Award</span> Winning
                    Software Development Company
                  </h2>
                  <h4 className="mb15">
                    #1 Software Company, Awarded for its Top Features/Editor
                    Web/ Mobile Apps Delivered.
                  </h4>
                  <p>
                    Mount Saffron IT started its operation in the year 2023. We
                    are Worldwide, based on Web, App and Digital Marketing
                    Company. Our main Domain is Web Design, App Development,
                    Digital Marketing, Product Design and Cloud Services.
                    Customer Satisfaction and the Highest rate of Customer
                    Repeatability make us one of the pioneers in the field. So,
                    what are you waiting for? Let us discuss your ideas and our
                    innovation in detail because catering your requirement and
                    converting it into a final product is our main goal.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="agency-info-ree mt100">
            <div className="agency-picture">
              <img
                src="images/others/office-view.jpg"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="container">
              <div className="row key-and-award">
                <div className="ree-app-st-a flx-end m-order2">
                  <div className="btnctm">
                    <a
                      href="#about"
                      className="ree-btn ree-btn-grdt2 mw-80 m-mt30"
                    >
                      Know More Mount Saffron IT{" "}
                      <i className="fas fa-arrow-right fa-btn" />
                    </a>
                  </div>
                </div>
                <div className="ree-app-st-b m-order1">
                  <div className="quick-key-points">
                    <div className="key-highlights">
                      <div className="key-highlt-b">
                        <h3>
                          <span>15</span> <span>+</span>
                        </h3>
                        <p>Project Delivered</p>
                      </div>
                      <div className="key-highlt-b">
                        <h3>
                          <span>5 </span> <span>+</span>
                        </h3>
                        <p>Enterprise Clients</p>
                      </div>
                      <div className="key-highlt-b">
                        <h3>
                          <span>40 </span> <span>+</span>
                        </h3>
                        <p>Experts Team</p>
                      </div>
                      <div className="key-highlt-b">
                        <h3>
                          <span>1 </span> <span></span>
                        </h3>
                        <p>Success Year</p>
                      </div>
                    </div>
                    <div className="agency-info-anim text-center">
                      <div className="animated-logo">
                        <img
                          src="images/banner/mount.png"
                          width={50}
                          alt="Mount Saffron IT logo"
                          className="logo-anim"
                        />
                        <img
                          src="images/banner/app-development.png"
                          alt="Mount Saffron IT logo"
                          className="circle-anim"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt60">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="app-awards owl-carousesl">
                  <div className="award-achivt">
                    <a href="#">
                      <img
                        src="images/brand-logo/top-it.png"
                        alt="top it"
                        className="img-fluid"
                      />
                    </a>
                    <p>Top App Developer Genuine Quality</p>
                  </div>
                  <div className="award-achivt">
                    <a href="#">
                      <img
                        src="images/brand-logo/appfutura-logo.png"
                        alt="top it"
                        className="img-fluid"
                      />
                    </a>
                    <p>Top App Developer Genuine Quality</p>
                  </div>
                  <div className="award-achivt">
                    <a href="#">
                      <img
                        src="images/brand-logo/goodfirm-logo.png"
                        alt="top it"
                        className="img-fluid"
                      />
                    </a>
                    <p>Top App Developer Genuine Quality</p>
                  </div>
                  <div className="award-achivt">
                    <a href="#">
                      <img
                        src="images/brand-logo/clutch-logo.png"
                        alt="top it"
                        className="img-fluid"
                      />
                    </a>
                    <p>Top App Developer Genuine Quality</p>
                  </div>
                  <div className="award-achivt">
                    <a href="#">
                      <img
                        src="images/brand-logo/mobile-app-logo.png"
                        alt="top it"
                        className="img-fluid"
                      />
                    </a>
                    <p>Top App Developer Genuine Quality</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*home-about end*/}
        {/*start services*/}
        <section className="r-bg-i sec-pad" id="service">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sec-heading text-center">
                  <span className="sub-heading mb15">
                    Mount Saffron IT Services
                  </span>
                  <h2>
                    We build modern{" "}
                    <span className="ree-text rt40">creative</span>{" "}
                    <span className="ree-text rt40">Web</span> experiences.
                  </h2>
                </div>
              </div>
            </div>
            <div className="row mt30 ItServiceRow">
              <div
                className="col-lg-4 col-sm-6 ItService"
                data-aos="fade-up"
                data-aos-delay={1100}
              >
                <div className="ree-card r-bg-c  mt60">
                  <div className="ree-card-img">
                    <img
                      src="images/icons/app-development.svg"
                      alt="services"
                    />{" "}
                  </div>
                  <div className="ree-card-content mt40">
                    <h3 className="mb15">
                      <a href="#">Web App Development</a>
                    </h3>
                    <p>
                      Our expert Designers and Developers fully understand your
                      vision and business goals, crafting designs that align
                      perfectly with your objective
                    </p>
                  </div>
                  <div className="ree-card-content-link">
                    <a href="#" className="ree-card-link mt40">
                      Read More <i className="fas fa-arrow-right fa-btn" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-6 ItService"
                data-aos="fade-up"
                data-aos-delay={700}
              >
                <div className="ree-card r-bg-c  mt60">
                  <div className="ree-card-img">
                    <img src="images/icons/smartwatch.svg" alt="services" />{" "}
                  </div>
                  <div className="ree-card-content mt40">
                    <h3 className="mb15">
                      <a href="#">Wearable Development</a>
                    </h3>
                    <p>
                      Increase conversions. Increase leads. Increase sales.
                      Increase revenue. That's digital marketing services from
                      Mount Saffron IT.
                    </p>
                  </div>
                  <div className="ree-card-content-link">
                    <a href="#" className="ree-card-link mt40">
                      Read More <i className="fas fa-arrow-right fa-btn" />
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-sm-6 ItService"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="ree-card r-bg-c  mt60">
                  <div className="ree-card-img">
                    <img src="images/icons/cube.svg" alt="services" />{" "}
                  </div>
                  <div className="ree-card-content mt40">
                    <h3 className="mb15">
                      <a href="#">Hybrid App Development</a>
                    </h3>
                    <p>
                      Mount Saffron IT’s product design services cover entire
                      product design lifecycle, From prototyping to fully
                      functional design.
                    </p>
                  </div>
                  <div className="ree-card-content-link">
                    <a href="#" className="ree-card-link mt40">
                      Read More <i className="fas fa-arrow-right fa-btn" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-6 ItService"
                data-aos="fade-up"
                data-aos-delay={900}
              >
                <div className="ree-card r-bg-c  mt60">
                  <div className="ree-card-img">
                    <img src="images/icons/ux-interface.svg" alt="services" />{" "}
                  </div>
                  <div className="ree-card-content mt40">
                    <h3 className="mb15">
                      <a href="#">UI/UX Designing</a>
                    </h3>
                    <p>
                      Our highly experienced designers comprehend your vision
                      and business objectives in order to create the designs.
                    </p>
                  </div>
                  <div className="ree-card-content-link">
                    <a href="#" className="ree-card-link mt40">
                      Read More <i className="fas fa-arrow-right fa-btn" />
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-6 ItService"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="ree-card r-bg-c  mt60">
                  <div className="ree-card-img">
                    <img src="images/icons/apple.svg" alt="services" />{" "}
                  </div>
                  <div className="ree-card-content mt40">
                    <h3 className="mb15">
                      <a href="#">iOS Development</a>
                    </h3>
                    <p>
                      At Mount Saffron IT, we deliver pixel-perfect, responsive
                      iOS app development services, meticulously designed to
                      balance aesthetics.
                    </p>
                  </div>
                  <div className="ree-card-content-link">
                    <a href="#" className="ree-card-link mt40">
                      Read More <i className="fas fa-arrow-right fa-btn" />
                    </a>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-sm-6 ItService"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="ree-card r-bg-c mt60">
                  <div className="ree-card-img shadows">
                    <img src="images/icons/android.svg" alt="services" />{" "}
                  </div>
                  <div className="ree-card-content mt40">
                    <h3 className="mb15">
                      <a href="#"> Android Development</a>
                    </h3>
                    <p>
                      Our software house has been recognised by google for
                      outstanding android application quality and seamless user
                      experience.
                    </p>
                  </div>
                  <div className="ree-card-content-link">
                    <a href="#" className="ree-card-link mt40">
                      Read More <i className="fas fa-arrow-right fa-btn" />{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="cta-block-wide mt100">
              <div className="row justify-content-center text-center">
                <div className="col-lg-10 vcenter">
                  <div className="cta-heading-wide-bt">
                    <h3>
                      Hire World-Class{" "}
                      <span className="ree-text rt40">Developers</span>
                    </h3>
                    <a href="#" className="ree-btn  ree-btn-grdt1 mw-80">
                      Hire Now <i className="fas fa-arrow-right fa-btn" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*services end*/}
        {/*start work*/}

        <section class="r-bg-f sec-pad" id="work">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-lg-8 col-sm-8 vcenter text-center">
                <div class="heading-hz-btn">
                  <span class="sub-heading mb15">QUALITY WORK</span>
                  <h2 class="w-txt">
                    Some of our <span class="ree-text rt40">Finest</span> Work
                  </h2>
                </div>
              </div>
            </div>
            <div class="row mt60">
              <div class="col-lg-12 vcenter">
                {/* <OwlCarousel
                  className="full-work-app owl-nv owl-carousel"
                  {...optionsTest}
                >
                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        <img
                          src="images/portfolio/portfolio-1.jpg"
                          alt="portfolio mount saffron"
                          class="img-fluid"
                        />{" "}
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">UX, UI, Graphic Design</p>
                      <h4>
                        <a href="#">
                          {" "}
                          Furniture ios app kit design development{" "}
                        </a>{" "}
                      </h4>
                    </div>
                  </div>
                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        {" "}
                        <img
                          src="images/portfolio/portfolio-2.jpg"
                          alt="portfolio mount saffron"
                          class="img-fluid"
                        />{" "}
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">UX, UI, Graphic Design</p>
                      <h4>
                        <a href="#">Creative business card design service </a>
                      </h4>
                    </div>
                  </div>
                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        {" "}
                        <img
                          src="images/portfolio/portfolio-3.jpg"
                          alt="portfolio mount saffron"
                          class="img-fluid"
                        />{" "}
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">UX, UI, Graphic Design</p>
                      <h4>
                        <a href="#">
                          {" "}
                          Furniture ios app kit design development{" "}
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        {" "}
                        <img
                          src="images/portfolio/portfolio-2.jpg"
                          alt="portfolio mount saffron"
                          class="img-fluid"
                        />{" "}
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">UX, UI, Graphic Design</p>
                      <h4>
                        <a href="#">
                          {" "}
                          Furniture ios app kit design development{" "}
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        {" "}
                        <img
                          src="images/portfolio/portfolio-2.jpg"
                          alt="portfolio mount saffron"
                          class="img-fluid"
                        />{" "}
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">UX, UI, Graphic Design</p>
                      <h4>
                        <a href="#">Creative business card design service </a>
                      </h4>
                    </div>
                  </div>
                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        {" "}
                        <img
                          src="images/portfolio/portfolio-3.jpg"
                          alt="portfolio mount saffron"
                          class="img-fluid"
                        />{" "}
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">UX, UI, Graphic Design</p>
                      <h4>
                        <a href="#">
                          {" "}
                          Furniture ios app kit design development{" "}
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        {" "}
                        <img
                          src="images/portfolio/portfolio-2.jpg"
                          alt="portfolio mount saffron"
                          class="img-fluid"
                        />{" "}
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">UX, UI, Graphic Design</p>
                      <h4>
                        <a href="#">
                          {" "}
                          Furniture ios app kit design development{" "}
                        </a>
                      </h4>
                    </div>
                  </div>
                </OwlCarousel> */}
                <OwlCarousel
                  className="full-work-app owl-nv owl-carousel"
                  {...optionsTest}
                >
                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        <img
                          src="images/portfolio/portfolio-1.jpg"
                          alt="Web Application Project 1"
                          class="img-fluid"
                        />
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">Web Development, UX Design</p>
                      <h4>
                        <a href="#"> Responsive E-Commerce Web App</a>
                      </h4>
                    </div>
                  </div>

                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        <img
                          src="images/portfolio/portfolio-2.jpg"
                          alt="Web Application Project 2"
                          class="img-fluid"
                        />
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">Web Design, Frontend Development</p>
                      <h4>
                        <a href="#">Custom Admin Dashboard Development </a>
                      </h4>
                    </div>
                  </div>

                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        <img
                          src="images/portfolio/portfolio-3.jpg"
                          alt="Web Application Project 3"
                          class="img-fluid"
                        />
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">Full-stack Development</p>
                      <h4>
                        <a href="#"> CRM System Web Application Development </a>
                      </h4>
                    </div>
                  </div>

                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        <img
                          src="images/portfolio/portfolio-2.jpg"
                          alt="Web Application Project 4"
                          class="img-fluid"
                        />
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">Backend Integration, API </p>
                      <h4>
                        <a href="#">Web Application for Project Management</a>
                      </h4>
                    </div>
                  </div>

                  <div class="fwb-main-x fwb-a">
                    <div class="work-thumbnails">
                      <a href="#">
                        <img
                          src="images/portfolio/portfolio-3.jpg"
                          alt="Web Application Project 5"
                          class="img-fluid"
                        />
                      </a>
                    </div>
                    <div class="work-details">
                      <p class="mb10">UI/UX, Web Development</p>
                      <h4>
                        <a href="#">
                          {" "}
                          SaaS Web Application for Task Management{" "}
                        </a>
                      </h4>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
            <div class="row justify-content-center text-center mt60">
              <div class="col-lg-10">
                <div class="cta-heading-wide-bt">
                  <h3 class="w-txt">Take a look of our quality work</h3>
                  <a href="#" class="ree-btn  ree-btn-grdt1 mw-80 no-shadows">
                    View All Work <i class="fas fa-arrow-right fa-btn"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end work*/}
        {/*start technologies*/}
        <section className="r-bg-x zup sec-pad">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sec-heading text-center">
                  <h2>
                    <span className="ree-text rt40">Technologies</span> we work
                    with
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt30">
              <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img src="images/Azure.png" alt="Apswiftple" />{" "}
                    </div>
                    <div className="iwt-content">
                      <p>Azure</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img
                        src="images/netfinal-removebg-preview.png"
                        alt="Java"
                      />
                    </div>
                    <div className="iwt-content">
                      <p>.Net</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img
                        src="images/icons/physics.svg"
                        alt="React Native"
                      />{" "}
                    </div>
                    <div className="iwt-content">
                      <p>React </p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img src="images/vue.png" style={{width:"55"}} alt="Flutter" />{" "}
                    </div>
                    <div className="iwt-content">
                      <p>Vue.js</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img src="images/AngularLogo.png" alt="Flutter" />{" "}
                    </div>
                    <div className="iwt-content">
                      <p>Angular</p>
                    </div>
                  </a>
                </div>
              </div>
           
              <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img src="images/Aws.png" alt="pwa" />
                    </div>
                    <div className="iwt-content">
                      <p>AWS</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img
                        src="images/pthon-removebg-preview.png"
                        alt="python"
                      />{" "}
                    </div>
                    <div className="iwt-content">
                      <p>Python</p>
                    </div>
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img src="images/NetCore.png" alt="Java" />
                    </div>
                    <div className="iwt-content">
                      <p>.Net Core</p>
                    </div>
                  </a>
                </div>
              </div> */}
              <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img src="images/sql.png" alt="Java" />
                    </div>
                    <div className="iwt-content">
                      <p>SQL</p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img src="images/icons/android.svg" alt="Android" />{" "}
                    </div>
                    <div className="iwt-content">
                      <p>Android</p>
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                <div className="icon-with-title">
                  <a href="javascript:void(0)">
                    <div className="iwt-icon">
                      {" "}
                      <img src="images/icons/apple.svg" alt="Apple" />{" "}
                    </div>
                    <div className="iwt-content">
                      <p>iOS</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end technologies*/}
        {/*start home review*/}
        <section className="r-bg-c sec-pad" id="review">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="heading-review text-center">
                  <ul className="row justify-content-center text-center mb60">
                    <li className="col-lg-2 col-md-3 col-sm-4 col-4">
                      <div className="brand-logo">
                        <img
                          src="images/brand-logo/customer-logo-1.png"
                          alt="clients"
                          className="img-fluid"
                        />
                      </div>
                    </li>
                    <li className="col-lg-2 col-md-3 col-sm-4 col-4">
                      <div className="brand-logo">
                        <img
                          src="images/brand-logo/customer-logo-2.png"
                          alt="clients"
                          className="img-fluid"
                        />
                      </div>
                    </li>
                    <li className="col-lg-2 col-md-3 col-sm-4 col-4">
                      <div className="brand-logo">
                        <img
                          src="images/brand-logo/customer-logo-3.png"
                          alt="clients"
                          className="img-fluid"
                        />
                      </div>
                    </li>
                    <li className="col-lg-2 col-md-3 col-sm-4 col-4">
                      <div className="brand-logo">
                        <img
                          src="images/brand-logo/customer-logo-4.png"
                          alt="clients"
                          className="img-fluid"
                        />
                      </div>
                    </li>
                    <li className="col-lg-2 col-md-3 col-sm-4 col-4">
                      <div className="brand-logo">
                        <img
                          src="images/brand-logo/customer-logo-5.png"
                          alt="clients"
                          className="img-fluid"
                        />
                      </div>
                    </li>
                    <li className="col-lg-2 col-md-3 col-sm-4 col-4">
                      <div className="brand-logo">
                        <img
                          src="images/brand-logo/customer-logo-6.png"
                          alt="clients"
                          className="img-fluid"
                        />
                      </div>
                    </li>
                  </ul>
                  <h2 className="mb50">
                    Our <span className="ree-text rt40">clients</span>{" "}
                    <span className="ree-text rt40">praise</span> us for our
                    great results
                  </h2>
                  <OwlCarousel
                    className="testimonial-card-b owl-carousel mt30"
                    {...optionClients}
                  >
                    <div className="review-slider">
                      <h4 className="mb20">
                        "Mount Saffron IT build my startup business a great
                        website that really helps current and potential
                        customers get a good feel for my company."
                      </h4>
                      <p>
                        I am delighted with our new website. A1 service and
                        design by Mount Saffron IT. You can go with the Web
                        Design Company, and I 100% recommend them to anyone. The
                        perfect company to go to for your ideal website and
                        digital marketing.
                      </p>
                      <div className="reviewers mt30">
                        <div class="reviewers-img">
                          {" "}
                          <img src="images/users/user1.jpg" alt="client" />{" "}
                          <a
                            href="https://www.youtube.com/watch?v=7e90gBu4pas?autoplay=1&amp;rel=0"
                            class="play-vid-review video-popup"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Play Video Review"
                          >
                            <i class="fas fa-play"></i>
                          </a>{" "}
                        </div>
                        <div className="reviewers-nam">
                          <h4>Anne Fibbiyon</h4>
                          <p>Dallas, USA</p>
                        </div>
                      </div>
                    </div>
                    <div className="review-slider">
                      <h4 className="mb20">
                        "Mount Saffron IT build my startup business a great
                        website that really helps current and potential
                        customers get a good feel for my company."
                      </h4>
                      <p>
                        I was facing multiple issues with my old website and
                        that i discovered Mount Saffron IT. they resolved all
                        the problems in the website they also helped me with
                        marketing. which has helped me to acquire more
                        customers. Retention on the website has also increased.
                      </p>
                      <div className="reviewers mt30">
                        <div class="reviewers-img">
                          {" "}
                          <img src="images/users/user2.jpg" alt="client" />{" "}
                          <a
                            href="https://www.youtube.com/watch?v=7e90gBu4pas?autoplay=1&amp;rel=0"
                            class="play-vid-review video-popup"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Play Video Review"
                          >
                            <i class="fas fa-play"></i>
                          </a>{" "}
                        </div>
                        <div className="reviewers-nam">
                          <h4>Mayank Jhalana</h4>
                          <p>Jaipur, Rajasthan</p>
                        </div>
                      </div>
                    </div>
                    <div className="review-slider">
                      <h4 className="mb20">
                        "Mount Saffron ITbuild my startup business a great
                        website that really helps current and potential
                        customers get a good feel for my company."
                      </h4>
                      <p>
                        I was facing multiple issues with my old website and
                        that i discovered Mount Saffron IT. they resolved all
                        the problems in the website they also helped me with
                        marketing. which has helped me to acquire more
                        customers. Retention on the website has also increased.
                      </p>
                      <div className="reviewers mt30">
                        <div class="reviewers-img">
                          {" "}
                          <img src="images/users/user3.jpg" alt="client" />{" "}
                          <a
                            href="https://www.youtube.com/watch?v=7e90gBu4pas?autoplay=1&amp;rel=0"
                            class="play-vid-review video-popup"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Play Video Review"
                          >
                            <i class="fas fa-play"></i>
                          </a>{" "}
                        </div>
                        <div className="reviewers-nam">
                          <h4>Eric Shun</h4>
                          <p>Miskolc,Hungry </p>
                        </div>
                      </div>
                    </div>
                    <div className="review-slider">
                      <h4 className="mb20">
                        "Mount Saffron IT build my startup business a great
                        website that really helps current and potential
                        customers get a good feel for my company."
                      </h4>
                      <p>
                        I was facing multiple issues with my old website and
                        that i discovered Mount Saffron IT. they resolved all
                        the problems in the website they also helped me with
                        marketing. which has helped me to acquire more
                        customers. Retention on the website has also increased.
                      </p>
                      <div className="reviewers mt30">
                        <div class="reviewers-img">
                          {" "}
                          <img src="images/users/user4.jpg" alt="client" />{" "}
                          <a
                            href="https://www.youtube.com/watch?v=7e90gBu4pas?autoplay=1&amp;rel=0"
                            class="play-vid-review video-popup"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Play Video Review"
                          >
                            <i class="fas fa-play"></i>
                          </a>{" "}
                        </div>
                        <div className="reviewers-nam">
                          <h4>Gene Jacket</h4>
                          <p>Tuklic, UK</p>
                        </div>
                      </div>
                    </div>
                    <div className="review-slider">
                      <h4 className="mb20">
                        "Mount Saffron IT build my startup business a great
                        website that really helps current and potential
                        customers get a good feel for my company."
                      </h4>
                      <p>
                        I was facing multiple issues with my old website and
                        that i discovered Mount Saffron IT. they resolved all
                        the problems in the website they also helped me with
                        marketing. which has helped me to acquire more
                        customers. Retention on the website has also increased.
                      </p>
                      <div className="reviewers mt30">
                        <div class="reviewers-img">
                          {" "}
                          <img src="images/users/user5.jpg" alt="client" />{" "}
                          <a
                            href="https://www.youtube.com/watch?v=7e90gBu4pas?autoplay=1&amp;rel=0"
                            class="play-vid-review video-popup"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Play Video Review"
                          >
                            <i class="fas fa-play"></i>
                          </a>{" "}
                        </div>
                        <div className="reviewers-nam">
                          <h4>Marion Gaze</h4>
                          <p>Olivia, USA</p>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>

                  {/* <div className="clients-creative-pic mt30">
                    <div className="owl-dots" id="testimonials-avatar">
                      <button className="dot-c1 tcc1 owl-dot mt30">
                        {" "}
                        <img alt="image" src="images/users/user5.jpg" />
                      </button>
                      <button className="dot-c1 tcc2 owl-dot mt30">
                        {" "}
                        <img alt="image" src="images/users/user4.jpg" />
                      </button>
                      <button className="dot-c1 tcc3 owl-dot active mt30">
                        {" "}
                        <img alt="image" src="images/users/user3.jpg" />
                      </button>
                      <button className="dot-c1 tcc4 owl-dot mt30">
                        {" "}
                        <img alt="image" src="images/users/user2.jpg" />
                      </button>
                      <button className="dot-c1 tcc5 owl-dot mt30">
                        {" "}
                        <img alt="image" src="images/users/user1.jpg" />
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*end home review */}
        {/*download app*/}
        <section class="home-app-block r-bg-x sec-pad">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 vcenter">
                <div class="sec-heading pera-block">
                  <span class="sub-heading mb15">Download Our App</span>
                  <h2>Download our application from App Store</h2>
                  <p>
                    Download our application from the App Store. Get access to
                    exclusive features, personalized content, and stay connected
                    on the go. Enjoy a seamless experience with our easy-to-use
                    app! Let me know if you'd like any further changes!
                  </p>
                  <div class="btn-sets mt40">
                    <a href="#about" class="ree-btn  ree-btn-grdt1 mr20">
                      Play Store <i class="fab fa-android ml10"></i>
                    </a>
                    <a href="#about" class="ree-btn  ree-btn-grdt2 mr20">
                      iOS Store <i class="fab fa-apple ml10"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 vcenter">
                <div class="sol-image m-mt30">
                  <img
                    src="images/others/app-ui.png"
                    alt="our app"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*download app end*/}
        {/*start blogs  */}
        <section className="half-bg-ree sec-pad" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sec-heading text-center">
                  <span className="sub-heading mb15">Latest from blog</span>
                  <h2 className="mb15">
                    <span className="ree-text rt40">Read</span> stories, tips,
                    and our opinions on everything
                  </h2>
                  <p>
                    Get updated with the latest trends in technology and know
                    how your business can benefit from these technologies.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt30 BlogRow">
              <div
                className="col-lg-4 mt30 BlogCol4"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="half-blog-card">
                  <div className="half-blog-img">
                    {" "}
                    <a href="#blog">
                      <img
                        src="images/blogs/blog-img-1.jpg"
                        alt="blogs"
                        className="img-fluid"
                      />{" "}
                    </a>{" "}
                  </div>
                  <div className="half-blog-content">
                    <div className="blog-quick-inf mb20">
                      <span>
                        <i className="far fa-calendar-alt" /> 12 March 24
                      </span>{" "}
                      <span>
                        <i className="fas fa-clock" /> 5 Min Read
                      </span>{" "}
                    </div>
                    <h4 className="mb15">
                      <a href="#blog">
                        Best Front-End Frameworks and Libraries for Web Applications
                      </a>
                    </h4>
                    <p>
                    The best font-end frameworks and libraries for web applications include React, Angular and Vue.js, Which provide scalable, dynamic user interfaces
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 mt30 BlogCol4"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="half-blog-card">
                  <div className="half-blog-img">
                    {" "}
                    <a href="#blog">
                      <img
                        src="images/blogs/blog-img-1a.jpg"
                        alt="blogs"
                        className="img-fluid"
                      />{" "}
                    </a>{" "}
                  </div>
                  <div className="half-blog-content">
                    <div className="blog-quick-inf mb20">
                      <span>
                        <i className="far fa-calendar-alt" /> 04 June 24
                      </span>{" "}
                      <span>
                        <i className="fas fa-clock" /> 3 Min Read
                      </span>{" "}
                    </div>
                    <h4 className="mb15">
                      <a href="#blog">
                 Key Features of the .NET Framework:Enhancing Development Efficiency
                      </a>
                    </h4>
                    <p>
                    The .NET Framework provides a robust environment for developing modern applications with improved efficiency. 
  Key features include enhanced routing, seamless integration with libraries.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 mt30 BlogCol4"
                data-aos="fade-up"
                data-aos-delay={800}
              >
                <div className="half-blog-card">
                  <div className="half-blog-img">
                    {" "}
                    <a href="#blog">
                      <img
                        src="images/blogs/blog-img-1b.jpg"
                        alt="blogs"
                        className="img-fluid"
                      />{" "}
                    </a>{" "}
                  </div>
                  <div className="half-blog-content">
                    <div className="blog-quick-inf mb20">
                      <span>
                        <i className="far fa-calendar-alt" /> 15 Sept 24
                      </span>{" "}
                      <span>
                        <i className="fas fa-clock" /> 4 Min Read
                      </span>{" "}
                    </div>
                    <h4 className="mb15">
                      <a href="#blog">
                      Leading Cloud Providers for Scalable and Reliable Solutions
                      </a>
                    </h4>
                    <p>
                    Leading cloud providers like Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP) offer scalable and reliable solutions for businesses of all sizes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="contact" />
        </section>
        {/*end blogs*/}
        {/*start contact block*/}
        <section
          className="home-contact pb120"
          data-background="images/others/office.jpg"
        >
          <div className="container">
            <div className="row zup flx-end">
              <div className="col-right-a">
                <div className="sec-heading fourc-up-a">
                  <span className="sub-heading mb15">Inquiry</span>
                  <h2>
                    Let’s Get in <span className="ree-text rt40">Touch</span>
                  </h2>
                </div>
                <div className="home-contact-block">
                  <div className="contact-infos">
                    <div className="c-infot">
                      <span>Sales Department</span>{" "}
                      <a href="tel:911234567890" style={{ fontSize: "14px" }}>
                        <i className="fas fa-phone-alt" /> 9024821468
                      </a>
                    </div>
                    <div className="c-infot" style={{ fontSize: "14px" }}>
                      <span>HR Department</span>{" "}
                      <a href="tel:911234567890">
                        <i className="fas fa-phone-alt" /> 9024821468
                      </a>
                    </div>
                  </div>
                  <div className="contact-infos mt35">
                    <div className="c-infot">
                      <span>Sales Department</span>{" "}
                      <a
                        href="mailto:mount saffron@abc.com"
                        style={{ fontSize: "14px" }}
                      >
                        <i className="fas fa-envelope" />{" "}
                        info@mountsaffronit.com
                      </a>
                    </div>
                    <div className="c-infot">
                      <span>Connect on Whatsapp</span>{" "}
                      <a
                        href="https://wa.me/9024821468"
                        style={{ fontSize: "14px" }}
                      >
                        <i
                          className="fab fa-whatsapp"
                          style={{ marginRight: "5px" }}
                        />
                        9024821468
                      </a>
                    </div>
                  </div>
                  <div className="live-review- mt60">
                    <span>Read Our Customers Feedback</span>
                    <div className="livrve">
                      <a>
                        <img src="images/icons/google-logo.svg" alt="review" />{" "}
                      </a>
                      <a>
                        <img src="images/icons/hubspot-logo.svg" alt="review" />{" "}
                      </a>
                      <a>
                        <img
                          src="images/icons/trustpilot-logo.svg"
                          alt="review"
                        />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-right-b">
                <div className="form-contact-hom fourc-up-b">
                  <div className="form-block">
                    <div className="form-head">
                      <h3>Please fill in the form below.</h3>
                    </div>
                    <div className="form-body">
                      <form
                        action="#"
                        method="post"
                        name="feedback-form"
                        onSubmit={handleSubmit}
                        noValidate
                        className={validated ? "was-validated" : ""}
                      >
                        <div className="fieldsets row">
                          <div className="col-md-6">
                            <input
                              type="text"
                              placeholder="Full Name"
                              name="name"
                              value={contactForm.name}
                              onChange={handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="email"
                              placeholder="Email Address"
                              name="email"
                              value={contactForm.email}
                              onChange={handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="fieldsets row">
                          <div className="col-md-6">
                            <input
                              type="number"
                              placeholder="Contact Number"
                              name="phone"
                              value={contactForm.phone}
                              onChange={handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              placeholder="Skype"
                              name="skype"
                              value={contactForm.skype}
                              onChange={handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="fieldsets row">
                          <div className="col-md-6">
                            <input
                              type="text"
                              placeholder="Subject"
                              name="subject"
                              value={contactForm.subject}
                              onChange={handleChange}
                              required
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6">
                            <select
                              name="websiteName"
                              value={contactForm.websiteName}
                              onChange={handleChange}
                              required
                              className="form-control"
                            >
                              <option value="">Interested in</option>
                              <option value="Web Design">Web Design</option>
                              <option value="Graphic Design">
                                Graphic Design
                              </option>
                              <option value="App Development">
                                App Development
                              </option>
                              <option value="E-commerce Development">
                                E-commerce Development
                              </option>
                              <option value="Digital Marketing">
                                Digital Marketing
                              </option>
                              <option value="Business Growth">
                                Business Growth
                              </option>
                              <option value="Business Startup">
                                Business Startup
                              </option>
                              <option value="Other">Other...</option>
                            </select>
                          </div>
                        </div>
                        <div className="fieldsets">
                          <textarea
                            placeholder="Message"
                            name="message"
                            value={contactForm.message}
                            onChange={handleChange}
                            required
                            className="form-control"
                          />
                        </div>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck"
                            name="agreeToTerms"
                            checked={contactForm.agreeToTerms}
                            onChange={handleChange}
                            required
                          />
                          <label
                            className="custom-control-label label-f-form"
                            htmlFor="customCheck"
                          >
                            I agree to the{" "}
                            <a href="javascript:void(0)">
                              Terms &amp; Conditions
                            </a>{" "}
                            of Mount Saffron IT
                          </label>
                        </div>
                        <div className="fieldsets mt20">
                          <button
                            type="submit"
                            name="submit"
                            className="ree-btn ree-btn-grdt1 w-100"
                          >
                            Send your inquiry{" "}
                            <i className="fas fa-arrow-right fa-btn" />
                          </button>
                        </div>
                        <p className="trm">
                          <i className="fas fa-lock" /> We hate spam, and we
                          respect your privacy.
                        </p>
                      </form>

                      {/* <form action="#" method="post" name="feedback-form">
  <div className="fieldsets row">
    <div className="col-md-6">
      <input type="text" placeholder="Full Name" name="name" />{" "}
      
    </div>
    <div className="col-md-6">
      <input type="email" placeholder="Email Address" name="email" />
    </div>
  </div>
  <div className="fieldsets row">
    <div className="col-md-6">
      <input type="number" placeholder="Contact Number" name="phone" />
    </div>
    <div className="col-md-6">
      <input type="text" placeholder="Skype" name="skype" />
    </div>
  </div>
  <div className="fieldsets row">
    <div className="col-md-6">
      <input type="text" placeholder="Subject" name="subject" />
    </div>
    <div className="col-md-6">
      <select>
        <option>interested in</option>
        <option>Web Design</option>
        <option>Graphic Design</option>
        <option>App Development</option>
        <option>E-commerce Development</option>
        <option>Digital Marketing</option>
        <option>Business Growth</option>
        <option>Business Startup</option>
        <option>Other...</option>
      </select>
    </div>
  </div>
  <div className="fieldsets">
    <textarea placeholder="Message" name="message" defaultValue={""} />
  </div>
  <div className="custom-control custom-checkbox">
    <input
      type="checkbox"
      className="custom-control-input"
      id="customCheck"
      name="example1"
      defaultChecked="checked"
    />
    <label className="custom-control-label label-f-form" htmlFor="customCheck">
      I agree to the <a href="javascript:void(0)">Terms &amp; Conditions</a> of
      Reevan.
    </label>
  </div>
  <div className="fieldsets mt20">
    {" "}
    <button
      type="submit"
      name="submit"
      className="ree-btn  ree-btn-grdt1 w-100"
    >
      Send your inquiry <i className="fas fa-arrow-right fa-btn" />
    </button>{" "}
  </div>
  <p className="trm">
    <i className="fas fa-lock" />
    We hate spam, and we respect your privacy.
  </p>
</form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*start contact block*/}
        {/* start locations */}
        <div className="location-home sec-pad">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4">
                <div className="location-block- mt60">
                  <div className="loc-icon-nam">
                    <img src="images/icons/new-delhi.svg" alt="new-delhi" />
                    <p>
                      <span className="ree-text rt40">India</span>
                    </p>
                  </div>
                  <p className="pt20 pb20">
                    E-24, 3rd Floor, Saraswati Nagar, Near Jaipur International
                    Airport, Jaipur 302017, Rajasthan, India
                  </p>
                  <div className="loc-contct">
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Map Location"
                    >
                      <i className="fas fa-map-marker-alt" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Phone Number"
                    >
                      <i className="fas fa-phone-alt" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Email Address"
                    >
                      <i className="fas fa-envelope" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Skype Id"
                    >
                      <i className="fab fa-whatsapp" />
                    </a>
                    <a href="https://www.linkedin.com/company/mount-saffron-technologies-and-consultant-pvt-ltd/posts/?feedView=all" target="_blank">
                        <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                      </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="location-block- mt60">
                  <div className="loc-icon-nam">
                    <img
                      src="images/icons/burj-al-arab.svg"
                      alt="burj-al-arab"
                    />
                    <p>
                      <span className="ree-text rt40">Dubai</span>
                    </p>
                  </div>
                  <p className="pt20 pb20">Comming Soon</p>
                  <div className="loc-contct">
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Map Location"
                    >
                      <i className="fas fa-map-marker-alt" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Phone Number"
                    >
                      <i className="fas fa-phone-alt" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Email Address"
                    >
                      <i className="fas fa-envelope" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Skype Id"
                    >
                      <i className="fab fa-whatsapp" />
                    </a>
                    <a href="https://www.linkedin.com/company/mount-saffron-technologies-and-consultant-pvt-ltd/posts/?feedView=all" target="_blank">
                        <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                      </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                {/* <div className="location-block- mt60">
                  <div className="loc-icon-nam">
                    <img src="images/icons/big-ben.svg" alt="big-ben" />
                    <p>
                      <span className="ree-text rt40">UK</span>
                    </p>
                  </div>
                  <p className="pt20 pb20">
                  E-24, 3rd floor, Saraswati Nagar, Near Jaipur International Airport, Jaipur 302017, Rajasthan, India
                  </p>
                  <div className="loc-contct">
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Map Location"
                    >
                      <i className="fas fa-map-marker-alt" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Phone Number"
                    >
                      <i className="fas fa-phone-alt" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Email Address"
                    >
                      <i className="fas fa-envelope" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      target="blank"
                      className="btn-outline rount-btn"
                      data-toggle="tooltip"
                      title="Skype Id"
                    >
                      <i className="fab fa-skype" />
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* end locations */}
        {/*start footer */}
      </>

      {/* <!-- Header End --> */}
      {/* <!--start footer --> */}
      <footer class="footer-a">
        <div class="footer-fist-row pt40">
          <div class="container">
            <div class="row footer-deg2">
              <div class="col-5s">
                <div class="footer-ree-c">
                  <div class="footer-logo">
                    <h5 style={{ cursor: "default" }}>
                      {/* <a href="#" style={{    color: "#adb7c5" }}> */}{" "}
                      {/* <img
                        src="images/footer-logo.png"
                        alt="reeven"
                        class="img"
                      />{" "} */}
                      Mount Saffron IT
                      {/* </a>  */}
                    </h5>
                  </div>
                  <p class="mt15">
                    Empowering businesses with cutting-edge IT solutions, we
                    help you drive success through technology, leveraging our
                    expertise to deliver impactful results in just over a year
                  </p>

                  <ul class="footer_social mt30">
                    <li>
                      <a href="#" target="_blank">
                        <i class="fab fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="#" target="_blank">
                        <i class="fab fa-facebook-f" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i class="fab fa-youtube" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/mount-saffron-technologies-and-consultant-pvt-ltd/posts/?feedView=all" target="_blank">
                        <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="#" target="_blank">
                        <i class="fab fa-instagram" aria-hidden="true"></i>
                      </a>{" "}
                    </li>
                  </ul>

                  <a
                    href="#contact"
                    class="ree-btn  ree-btn-grdt1 mw-80 no-shadows mt40"
                  >
                    Our Brochure <i class="fas fa-arrow-right fa-btn"></i>
                  </a>
                </div>
              </div>
              <div class="col-5s">
                <h5>For Work inquiry</h5>
                <ul class="footer-links-list social-linkz">
                  <li>
                    <a href="tel:9024821468">
                      <span>
                        <i class="fas fa-phone-square-alt"></i>
                      </span>
                      9024821468{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/9024821468">
                      <span>
                        <i class="fab fa-whatsapp-square"></i>
                      </span>{" "}
                      9024821468
                    </a>
                  </li>

                  <li>
                    <a href="mailto:career@mount saffron.com">
                      <span>
                        <i class="fas fa-envelope"></i>
                      </span>{" "}
                      info@mountsaffronit.com
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-5s">
                <h5>Company</h5>
                <ul class="footer-links-list">
                  <li>
                    <a href="#about"> About us</a>
                  </li>
                  <li>
                    <a href="#">Portfolio</a>
                  </li>
                  <li>
                    <a href="#blog">Blog</a>
                  </li>
                  <li>
                    <a href="#contact">Contact Us</a>
                  </li>
                  <li>
                    <a href="#">Faqs</a>
                  </li>
                  <li>
                    <a href="#">Privacy & Policy</a>
                  </li>
                </ul>
              </div>
              <div class="col-5s">
                <h5>Services</h5>
                <ul class="footer-links-list">
                  <li>
                    <a href="#">Hire Dedicated Developers</a>
                  </li>
                  <li>
                    <a href="#">Web App Development</a>
                  </li>
                  <li>
                    <a href="#">Mobile App Development</a>
                  </li>
                  <li>
                    <a href="#">Search Engine Optimization</a>
                  </li>
                  <li>
                    <a href="#">Pay-Per-Click</a>
                  </li>
                  <li>
                    <a href="#">Social Media Marketing</a>
                  </li>
                </ul>
              </div>
              <div class="col-5s">
                <h5>Industries</h5>
                <ul class="footer-links-list">
                  <li>
                    <a href="#">Healthcare</a>
                  </li>
                  <li>
                    <a href="#">Education</a>
                  </li>
                  <li>
                    <a href="#">Retail</a>
                  </li>
                  <li>
                    <a href="#">Logistics</a>
                  </li>
                  <li>
                    <a href="#">Oil & Gas</a>
                  </li>
                  <li>
                    <a href="#">Music & Video</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="container ft-cpy bt-top mt70">
            <div class="row">
              <div class="col-lg-5">
                <div class="ft-copyright">
                  {/* <p>We are tracking any intention of piracy.</p> */}
                </div>
              </div>
              <div class="col-lg-7">
                <div class="ft-copyright ft-r">
                  <p>
                    © Copyright All Rights Reserved Developed By Mount Saffron
                    IT
                    {/* <a
                      href="https://themeforest.net/user/rajesh-doot/portfolio"
                      target="blank"
                    >
                      Rajesh Doot
                    </a> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- end footer --> */}
    </>
  );
};

export default App;
